.market-data {
    h2 {
        margin-bottom: 12px; }

    >p {
        margin-bottom: 24px; }

    &__block {
        display: flex;
        align-items: center;
        gap: 32px;

        @media (max-width: 1680px) {
            gap: 32px;
            justify-content: space-between; }

        @media (max-width: $md) {
            flex-wrap: wrap;
            justify-content: initial; }

        .circle {
            border-radius: 100%;
            width: 8px;
            height: 8px;
            background: $blue;

            @media (max-width: $lg) {
                display: none; } } }

    &__item {
        position: relative;
        @media (max-width: $md) {
            width: 100%;
            max-width: calc( 50% - 16px ); }

        &:nth-child(1), &:nth-child(5), &:nth-child(9) {
            @media (max-width: $md) {
                &::after {
                    top: 30px;
                    position: absolute;
                    display: block;
                    content: '';
                    background: $blue;
                    right: 4px;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%; } } }
        p {
            @media (max-width: $md) {
                white-space: nowrap; }

            &:first-child {
                font-size: 16px;
                font-weight: 500;
                line-height: 160%;
                color: #5D5E67;
                margin-bottom: 8px;

                @media (max-width: $lg) {
                    font-size: 14px; } }

            &:last-child {
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                color: #2E3038;

                @media (max-width: $lg) {
                    font-size: 20px; } } } } }
