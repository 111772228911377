.analytics {
    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 24px; }

    &__item {
        border-radius: 24px;
        padding: 32px;
        background: $white;
        max-width: calc( 50% - 76px );

        @media (max-width: $md) {
            max-width: 100%;
            padding: 24px 16px; }

        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 24px;

            @media (max-width: $md) {
                font-size: 16px;
                line-height: 19px; } }

        &:last-child {
            .analytics__item-text {
                margin-bottom: 40px; } }

        img {
            width: 100%;

            @media (max-width: $md) {
                object-fit: cover; } }

        &-text {
            display: flex;
            gap: 32px;
            margin-bottom: 24px;

            div {
                span {
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    margin-bottom: 8px; }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 160%;

                    @media (max-width: $md) {
                        font-size: 12px; }

                    &:last-child {
                        margin-top: 4px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 120%; } }

                &:first-child {
                    span {
                        background: #3F60F2; } }

                &:nth-child(2) {
                    span {
                        background: #45CE81; } }

                &:nth-child(3) {
                    span {
                        background: #F8A460; } } } } }

    &__row {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $md) {
            flex-direction: column;
            gap: 24px;
            align-items: flex-start; }

        .table__header {
            display: flex;
            align-items: center;
            gap: 12px;

            .table__btns {
                display: flex;
                align-items: center;
                background: $white;
                height: 46px;
                padding: 4px 8px 4px 8px;
                border-radius: 8px;
                gap: 4px;

                &.time {
                    button {
                        padding: 0 12px; } }

                button {
                    padding: 0 24px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 160%;
                    color: $accent;
                    cursor: pointer;
                    transition: all 0.7s ease;
                    height: 100%;
                    border-radius: 8px;

                    &:hover, &.active {
                        background: white; } } } } } }
