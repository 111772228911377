// Starter template
@import './page/page';
@import "./hamburger/hamburger";
@import "./module/module";

// Components
@import "./footer/footer";
@import "./header/header";
@import "./overlay/overlay";

// Sections
@import "./sections/promo/promo";
@import "./sections/ethereum-markets/ethereum-markets";
@import "./sections/market-data/market-data";
@import "./sections/block-table/block-table";
@import "./sections/how-to-buy/how-to-buy";
@import "./sections/ethereum/ethereum";
@import "./sections/analytics/analytics";
@import "./sections/resources/resources";

@import "./sections/faq/faq";
