/**
 * Bootstrap
 */
@import './variables';

/**
 * Mixins
 */
@import '../../mixins/sass/sprite-img';
@import '../../mixins/sass/sprite-svg';
@import '../../mixins/sass/pos-center';
@import '../../mixins/sass/pos-strech';
@import '../../mixins/sass/responsive-title';
@import '../../mixins/sass/letter-spacing';
@import '../../mixins/sass/gradient';
@import '../../mixins/sass/font-face';

/**
 * Base
 */
@import './fonts';
@import './base';
@import './typography';
@import './png-icons';
@import './swiper';

/**
 * Components
 */
@import '../../components/components';

/**
 * Vendor styles
 */
@import '../../vendor/vendor';
