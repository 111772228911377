.promo {
    picture,svg {
        width: 100%;

        img {
            border-radius: 16px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            height: auto;

            @media (max-width: $lg) {
                width: 100%;
                height: auto; }

            @media (max-width: $md) {
                display: none;
                border-radius: 0; } } }

    h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 120%;
        color: #2E3038;
        margin-bottom: 12px;

        @media (max-width: $lg) {
            font-size: 32px; } }
    p {
        margin-bottom: 24px; } }
