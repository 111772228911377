.hamburger {
    align-items: center;
    display: none;
    flex-direction: column;
    outline: none;
    transition: transform .2s ease;
    justify-content: space-between;
    text-decoration: none;
    height: 22px;
    width: 32px;
    position: relative;
    z-index: 10;
    margin-left: auto;

    @media (max-width: $lg) {
        display: flex; }

    &::before, &::after, span {
        background: #5D5E67;
        border-radius: 5px;
        content: "";
        display: block;
        transition: transform .2s ease;
        height: 3px;
        width: 100%; }

    &.is-active {
        transition: transform .3s ease;

        span {
            opacity: 0; }

        &::before, &::after {
            width: 100%; }

        &::before, &::after {
            top: 50%;
            position: absolute; }

        &::after {
            transform: rotate(-45deg); }

        &::before {
            transform: rotate(45deg); } } }
