body {
	background: #F5F5F5;
	font-family: "Inter", sans-serif;
	margin: 0;
	font-weight: 500; }

.page {
	overflow-x: hidden;
	@media (min-width: 1921px) {
		max-width: none !important; }

	&__content {
		position: relative;
		overflow: initial;
		padding-top: 100px;

		@media (max-width: $lg) {
			padding-top: 100px; }

		> .container-main {
			display: flex;
			gap: 20px;

			@media (max-width: $lg) {
				flex-direction: column; } } } }

.container-main {
	max-width: 1690px;
	margin: 0 auto;

	@media (max-width: 1680px) {
		padding: 0 20px;
		max-width: 100%; }

	@media (max-width: $lg) {
		padding: 0 20px; }

	@media (max-width: $md) {
		padding: 0 8px; } }

section {
	padding: 40px;
	background: white;
	border-radius: 24px;

	@media (max-width: $md) {
		padding: 32px 16px; } }

.main {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 1260px;
	width: 100%;

	@media (max-width: 1680px) {
		max-width: calc( 100vw - 100px - 284px); }

	@media (max-width: $lg) {
		max-width: 100%; } }

.img-mob {
	display: none;

	@media (max-width: $md) {
		display: block;

		picture {
			display: block !important;

			img {
				display: block !important; } } } }

.sidebar {
	min-width: calc( 410px - 64px );
	max-width: calc( 410px - 64px );
	width: 100%;
	height: fit-content {
    //align-self: flex-start
    // top: 0
 }    //position: sticky
	padding: 32px;
	background: white;
	display: flex;
	flex-direction: column;
	gap: 40px;
	border-radius: 32px;

	@media (max-width: 1680px) {
		max-width: 260px;
		min-width: 260px; }

	@media (max-width: $lg) {
		max-width: calc( 100% - 64px ); }

	&__block {
		display: flex;
		flex-direction: column;
		gap: 24px;

		h3 {
			font-size: 20px;
			font-weight: 700;
			line-height: 120%; } }

	&__item {
		display: flex;
		align-items: center;

		svg, picture, img {
			margin-right: 12px; }

		&-text {
			p {
				&:first-child {
					font-size: 16px;
					font-weight: 700;
					line-height: 120%;
					margin-bottom: 2px; }
				&:last-child {
					font-size: 14px;
					font-weight: 500;
					line-height: 160%; } } }

		&-price {
			margin-left: auto;

			p {
				text-align: right;

				&:first-child {
					font-size: 14px;
					font-weight: 500;
					line-height: 160%; }

				&:last-child {
					font-size: 14px;
					font-weight: 500;
					line-height: 160%;
					color: $green; } } } } }

.section-title {
	font-family: Inter;
	font-size: 32px;
	font-weight: 700;
	line-height: 120%;
	color: #2E3038;

	@media (max-width: $lg) {
		font-size: 24px;
		line-height: 120%; } }

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 12px;
	text-decoration: none;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	transition-property: all;
	transition-duration: .6s;
	width: 100%;
	height: 52px;
	white-space: nowrap;
	max-width: 204px;
	font-family: "Inter", sans-serif;

	&:hover {
		opacity: 0.8;
		color: #FFFFFF; } }

table {
	border-collapse: collapse; }

h1,h2,h3,h4,h5,h6 {
	padding: 0;
	margin: 0;
	line-height: 150%;
	color: #2E3038; }

p {
	padding: 0;
	margin: 0;
	font-size: 18px;
	font-weight: 400;
	line-height: 156%;
	color: #5D5E67; }

a {
	text-decoration: none; }

button {
	border: none;
	transition-property: all;
	transition-duration: .6s;
	padding: 0;
	background: none;
	font-family: "Inter", sans-serif;

	&:hover {
		opacity: 0.8; } }

@keyframes opacity {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

.text-page {
	padding-top: 24px;

	h1 {
		margin-bottom: 24px; }

	h2 {
		margin-bottom: 12px; }

	p {
		margin-bottom: 12px; }

	ul {
		list-style: disc;
		padding-left: 24px;
		margin-bottom: 12px;

		li {
			color: $accent;
			font-size: 20px;
			font-weight: 400;
			line-height: 180%; } } }

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	@media (max-width: $md) {
		min-width: 650px;
		padding-bottom: 12px; }

	.button {
		padding: 0 32px;
		height: 44px;
		border-radius: 100px;
		background: $white;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		color: #5D5E67;
		width: fit-content; } }

.section-block {
	display: flex;
	flex-direction: column;
	gap: 32px;

	@media (max-width: $md) {
		overflow: hidden;
		gap: 24px; }

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $md) {
			flex-direction: column;
			align-items: flex-start;
			gap: 24px; }

		a {
			color: $blue;
			display: flex;
			align-items: center;
			gap: 8px; } }

	>p {
		font-size: 16px;
		font-weight: 500;
		line-height: 160%; } }

.overflow {
	@media (max-width: $lg) {
		overflow-x: scroll; } }

.table {
	width: 100%;
	background: #F5F5F5;
	border-radius: 24px;

	tr {
		height: 60px;
		border-bottom: 1px solid #EFF2F5;

		&:last-child {
			border: none; }

		th {
			font-size: 16px;
			font-weight: 700;
			line-height: 120%;
			color: #2E3038 !important;
			border-bottom: 1px solid #EFF2F5;

			&:first-child {
				padding-left: 32px; }

			&:last-child {
				text-align: right;
				padding-right: 32px; } }

		td {
			font-size: 16px;
			font-weight: 500;
			line-height: 160%;
			color: #5D5E67;

			&:first-child {
				padding-left: 32px;
				font-size: 18px;
				font-weight: 500;
				line-height: 160%; }

			&:last-child {
				text-align: right;
				padding-right: 32px;
				border-bottom: none; } } }

	&.type-1 {
		@media (max-width: $lg) {
			min-width: 1100px; }
		tr {
			td {
				&:nth-child(2) {
					font-weight: 700; }
				&:nth-child(3) {
					color: $blue; }
				&:last-child {
					max-width: 0%;
					width: 100%;
					span {
						background: $green;
						color: #F0F0FB;
						padding: 6px 29px;
						border-radius: 1000px; } } } } }

	&.type-2 {
		@media (max-width: $lg) {
			min-width: 1100px; }
		tr {
			th {
				&:nth-child(2) {
					width: 100%;
					max-width: 16%; }
				&:nth-child(3), &:nth-child(4), &:nth-child(5) {
					text-align: right;
					width: 100%;
					max-width: 20%; } }
			td {
				&:nth-child(2) {
					display: flex;
					align-items: center;
					gap: 12px;
					height: 60px;

					p {
						font-size: 16px;
						font-weight: 700;
						line-height: 120%; } }

				&:nth-child(3), &:nth-child(4), &:nth-child(5), , &:nth-child(6) {
					text-align: right;
					width: 20%;

					span {
						color: $green; } } } } }

	&.type-3 {
		@media (max-width: $md) {
			min-width: 420px; }
		tr {
			th {
				&:last-child {
					text-align: left; } }

			td {
				&:last-child {
					text-align: left; } } } } }

.graph {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		@media (max-width: $md) {
			flex-direction: column;
			gap: 12px;
			margin-bottom: 0;
			align-items: flex-start; } }

	&__btns {
		display: flex;
		align-items: center;
		background: $white;
		height: 46px;
		padding: 4px 8px 4px 8px;
		border-radius: 8px;
		gap: 4px;

		@media (max-width: $md) {
			width: 100%;
			max-width: calc( 100% - 16px ); }

		&.time {
			button {
				padding: 0 12px; } }

		button {
			padding: 0 24px;
			font-size: 14px;
			font-weight: 500;
			line-height: 160%;
			color: $accent;
			cursor: pointer;
			transition: all 0.7s ease;
			height: 100%;
			border-radius: 8px;

			@media (max-width: $md) {
				width: 100%; }

			&:hover, &.active {
				background: white; } } } }

.swiper-button-prev {
	transform: rotate(180deg); }

.pagination {
	margin-top: 32px;
	justify-content: center;
	display: flex;
	align-items: center;

	.swiper-pagination {
		display: flex;
		gap: 4px;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			width: 38px;
			height: 34px;
			color: #191B23;
			transition: all 0.7s ease;
			cursor: pointer;

			&.swiper-pagination-bullet-active, &.active {
				background: $blue;
				color: #EEF0FF; } } }

	.swiper-button-prev {
		margin-right: 16px;
		width: 24px;
		height: 24px;
		cursor: pointer; }

	.swiper-button-next {
		margin-left: 16px;
		width: 24px;
		height: 24px;
		cursor: pointer; } }
