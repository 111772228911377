$accent: #5D5E67;
$white: #F5F5F5;
$blue: #3F60F2;
$green: #45CE81;


$font-family-base: "Inter";

$lg: "1279px";
$sm: "1023px";
$md: "767px";
