.faq {
    position: relative;
    z-index: 2;

    h2 {
        margin-bottom: 32px;

        @media (max-width: $lg) {
            margin-bottom: 24px; } }

    >p {
        margin-bottom: 36px;
        max-width: 768px;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 160%;
        color: #A5AAC0; } }

.quiz__qna {
    gap: 16px;
    display: flex;
    flex-direction: column;

    @media (max-width: $lg) {
        gap: 12px; } }

.quiz__qna-list-item {
    position: relative;
    cursor: pointer;
    background: #FAFAFA;
    border-radius: 24px;
    padding: 24px; }

.quiz__qna-list-item-question {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    color: #2B3042;

    @media (max-width: $lg) {
        font-size: 16px;
        line-height: 19px; }

    @media (max-width: $md) {
        max-width: 260px; } }

.quiz__qna-list-item-answer {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    color: #5D5E67;

    &-text {
        margin-top: 14px;
        .link {
            margin-top: 28px; }

        ul {
            padding-left: 20px;
            margin-top: 16px;
            list-style: disc; } } }

.quiz__qna-list-item-close {
    position: absolute;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    right: 24px;
    top: 18px;
    transform: rotate(-90deg);

    @media (max-width: $lg) {
        top: 20px;
        right: 10px; }

    svg {
        width: 30px;
        height: 30px; } }

.quiz__qna-list-item.active {
    .quiz__qna-list-item-answer {
        max-height: initial;
        opacity: 1; }

    .quiz__qna-list-item-close {
        transform: rotate(0deg); } }
