.how-to-buy {
    h2 {
        margin-bottom: 24px; }

    &__slider {
        .swiper-slide {
            padding: 32px;
            border-radius: 12px;
            max-width: 50%;
            background: #FAFAFA;

            @media (max-width: $md) {
                max-width: 100%;
                padding: 32px 16px; }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blue;
                width: 44px;
                height: 44px;
                border-radius: 12px;
                font-size: 18px;
                font-weight: 500;
                line-height: 160%;
                color: #EEF0FF;
                margin-bottom: 24px; }

            h3 {
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 24px;

                @media (max-width: $md) {
                    font-size: 20px;
                    margin-bottom: 16px; } }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 160%; } } } }
