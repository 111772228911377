.block-table {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $lg) {
            flex-direction: column;
            align-items: baseline;
            gap: 8px; } }

    &__block {
        >p {
            color: #5D5E67;
            font-size: 18px;
            font-weight: 500;
            line-height: 180%;
            margin-bottom: 32px; }

        .bg-table {
            padding: 32px;
            background: #FAFAFA;
            border-radius: 16px;

            @media (max-width: $lg) {
                padding: 0; } }

        .table-v1, .table-v2, .table-v3, .table-v4 {
            width: 100%;
            background: white;
            border-radius: 16px;

            @media (max-width: $lg) {
                overflow-x: scroll;
                background: #FAFAFA; }

            table {
                @media (max-width: $lg) {
                    min-width: 700px; } }

            tr {
                height: 74px;

                td {
                    color: #5D5E67;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 25px;
                    border-bottom: 2px solid #FAFAFA;

                    &:nth-child(3), &:nth-child(4) {
                        text-align: center; }

                    &:first-child {
                        padding-left: 32px; }

                    &:last-child {
                        padding-right: 32px;
                        text-align: right; }

                    a {
                        color: #5D5E67; } }

                &:first-child {
                    height: 88px;

                    td {
                        color: #5D5E67;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 120%;
                        font-family: 'Outfit', sans-serif;

                        &:nth-child(3), &:nth-child(4) {
                            text-align: center; }

                        &:first-child {
                            padding-left: 32px; }

                        &:last-child {
                            padding-right: 32px;
                            text-align: right; } } }

                &:last-child {
                    td {
                        border-bottom: 0; } } } }

        .table-v1 {
            tr {
                td {
                    &:first-child {
                        max-width: 23%;
                        width: 100%; }

                    &:nth-child(2) {
                        text-align: center; } } }

            &.col-2-left {
                tr {
                    td {
                        &:nth-child(2) {
                            text-align: left; } } } }

            &.col-1-30 {
                tr {
                    td {
                        &:first-child {
                            max-width: 30%;
                            width: 100%; } } } } }

        .table-v2 {
            table {
                @media (max-width: $lg) {
                    min-width: 550px; } }
            tr {
                td {
                    max-width: 25%;
                    width: 100%;

                    &:nth-child(2), &:nth-child(3) {
                        text-align: center; } } } }

        .table-v3 {
            .row {
                picture {
                    float: inline-start;
                    margin-top: 6px;
                    margin-right: 16px;

                    img {
                        border-radius: 100%;
                        object-fit: cover; } } }

            td {

                &:first-child {
                    max-width: 20%;
                    width: 100%; }

                &:last-child {
                    max-width: 35%;
                    width: 100%; }

                &:nth-child(3) {
                    text-align: center; }

                .table-v3__text {
                    margin-top: 13px; } } }

        .table-v4 {
            table {
                @media (max-width: $lg) {
                    min-width: 650px; } }

            td {
                &:first-child {
                    max-width: 30%;
                    width: 100%;

                    @media (max-width: $lg) {
                        max-width: 20%; } }

                &:nth-child(2) {
                    @media (max-width: $lg) {
                        max-width: 20%;
                        width: 100%; } }

                &:last-child {
                    @media (max-width: $lg) {
                        max-width: 20%;
                        width: 100%; } } } } } }
