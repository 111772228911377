.overlay {
	background-color: rgba(black, .5);
	opacity: 0;
	visibility: hidden;
	position: fixed;
	transition: all .2s ease;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;

	&.is-active {
		visibility: visible;
		opacity: 1;
		transition: all .2s ease; } }

body {
	&.is-animate {
		.overlay {
			visibility: visible;
			opacity: 1;
			transition: all .2s ease; } } }
