.resources {
    h2 {
        margin-bottom: 24px; }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
            color: #5D5E67;
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 700;
            font-size: 16px;

            @media (max-width: $md) {
                font-size: 14px; } } } }
