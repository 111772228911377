.header {
    width: 100%;
    z-index: 11;
    transition: all 0.6s;
    position: absolute;
    top: 0;
    left: 0;

    .header__nav.mainmenu--js {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $lg) {
            flex-direction: column;
            justify-content: initial;
            gap: 24px; } }

    &__btns {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 1680px) {
            gap: 12px; }

        @media (max-width: $lg) {
            margin-left: 0; }

        .button {
            min-width: 154px;
            width: 100%;
            border-radius: 100px;
            background: $blue;
            border: 2px solid $blue;
            color: white;

            &:last-child {
                background: initial;
                color: #2E3038; } } }

    &__main {
        background: white;

        .container-main {
            display: flex;
            align-items: center;
            height: 80px;

            @media (max-width: $lg) {
                height: 80px;
                justify-content: initial; }

            @media (max-width: $md) {
                padding: 0 28px; } } }

    &__nav {
        margin-left: 32px;

        &.is-active {
            left: 0;
            transition: transform .3s ease; }

        @media (max-width: $lg) {
            margin-left: 0;
            min-width: 100vw;
            width: 100%;
            left: calc( -100vw - 10px );
            height: calc( 100vh - 104px );
            position: absolute;
            background: #00000052;
            backdrop-filter: blur(10px);
            top: 80px;
            padding-top: 48px;
            padding-bottom: 16px;
            z-index: 2;
            transition: transform .3s ease;
            justify-content: flex-start; }

        ul {
            display: flex;
            align-items: center;
            gap: 32px;

            @media (max-width: 1380px) {
                gap: 30px; }

            @media (max-width: $lg) {
                flex-direction: column;
                gap: 24px; }

            a {
                color: #71768B;
                font-size: 18px;
                font-weight: 500;
                line-height: 180%;

                @media (max-width: $lg) {
                    color: white; } } } }

    &__hamburger {
        display: none;

        @media (max-width: $lg) {
            display: block; } } }
