@use "sass:math";

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-add-circle {
    width: (40 / 40) * 1em;
}
.icon-arrow-btn {
    width: (36 / 37) * 1em;
}
.icon-arrow-experts {
    width: (19 / 19) * 1em;
}
.icon-arrow-long {
    width: (33 / 8) * 1em;
}
.icon-arrow-long-2 {
    width: (33 / 8) * 1em;
}
.icon-arrow-up {
    width: (32 / 32) * 1em;
}
.icon-arrow_forward_ios {
    width: (18 / 19) * 1em;
}
.icon-arrow_right_alt {
    width: (32 / 32) * 1em;
}
.icon-benefits-icon-1 {
    width: (49 / 48) * 1em;
}
.icon-benefits-icon-2 {
    width: (49 / 48) * 1em;
}
.icon-benefits-icon-3 {
    width: (49 / 48) * 1em;
}
.icon-benefits-icon-4 {
    width: (49 / 48) * 1em;
}
.icon-benefits-icon-5 {
    width: (49 / 48) * 1em;
}
.icon-benefits-icon-6 {
    width: (48 / 48) * 1em;
}
.icon-benefits-icon-7 {
    width: (48 / 48) * 1em;
}
.icon-benefits-icon-8 {
    width: (48 / 48) * 1em;
}
.icon-benefits-icon-9 {
    width: (48 / 48) * 1em;
}
.icon-close {
    width: (40 / 40) * 1em;
}
.icon-decor-1 {
    width: (150 / 427) * 1em;
}
.icon-decor-2 {
    width: (427 / 428) * 1em;
}
.icon-devices-icon-1 {
    width: (86 / 86) * 1em;
}
.icon-devices-icon-2 {
    width: (86 / 86) * 1em;
}
.icon-devices-icon-3 {
    width: (86 / 86) * 1em;
}
.icon-devices-icon-4 {
    width: (86 / 86) * 1em;
}
.icon-footer-adress {
    width: (19 / 20) * 1em;
}
.icon-footer-f {
    width: (24 / 25) * 1em;
}
.icon-footer-l {
    width: (24 / 25) * 1em;
}
.icon-footer-mail {
    width: (19 / 20) * 1em;
}
.icon-footer-mail-g {
    width: (19 / 20) * 1em;
}
.icon-footer-phone {
    width: (19 / 20) * 1em;
}
.icon-footer-w {
    width: (24 / 25) * 1em;
}
.icon-format-icon-1 {
    width: (81 / 81) * 1em;
}
.icon-format-icon-2 {
    width: (82 / 81) * 1em;
}
.icon-format-icon-3 {
    width: (81 / 81) * 1em;
}
.icon-format-icon-4 {
    width: (82 / 81) * 1em;
}
.icon-icon-li {
    width: (24 / 24) * 1em;
}
.icon-logo {
    width: (222 / 48) * 1em;
}
.icon-phone {
    width: (42 / 42) * 1em;
}
.icon-phone_in_talk {
    width: (34 / 34) * 1em;
}
.icon-promo-decor {
    width: (388 / 388) * 1em;
}
.icon-promo-decor-1 {
    width: (526 / 366) * 1em;
}
.icon-schedule {
    width: (76 / 76) * 1em;
}
.icon-separator {
    width: (19 / 19) * 1em;
}
.icon-separator-mob {
    width: (19 / 19) * 1em;
}
.icon-slider-r {
    width: (24 / 24) * 1em;
}
.icon-stars {
    width: (128 / 25) * 1em;
}
.icon-technologies-icon-1 {
    width: (48 / 48) * 1em;
}
.icon-technologies-icon-2 {
    width: (48 / 48) * 1em;
}
.icon-technologies-icon-3 {
    width: (48 / 48) * 1em;
}
.icon-technologies-icon-4 {
    width: (48 / 48) * 1em;
}
.icon-technologies-icon-5 {
    width: (48 / 48) * 1em;
}
.icon-technologies-icon-6 {
    width: (48 / 48) * 1em;
}
.icon-values-1 {
    width: (48 / 48) * 1em;
}
.icon-values-10 {
    width: (48 / 48) * 1em;
}
.icon-values-11 {
    width: (48 / 48) * 1em;
}
.icon-values-12 {
    width: (48 / 48) * 1em;
}
.icon-values-13 {
    width: (48 / 48) * 1em;
}
.icon-values-14 {
    width: (48 / 48) * 1em;
}
.icon-values-15 {
    width: (48 / 48) * 1em;
}
.icon-values-16 {
    width: (48 / 48) * 1em;
}
.icon-values-17 {
    width: (48 / 48) * 1em;
}
.icon-values-18 {
    width: (48 / 48) * 1em;
}
.icon-values-19 {
    width: (48 / 48) * 1em;
}
.icon-values-2 {
    width: (48 / 48) * 1em;
}
.icon-values-3 {
    width: (48 / 48) * 1em;
}
.icon-values-4 {
    width: (48 / 48) * 1em;
}
.icon-values-5 {
    width: (48 / 48) * 1em;
}
.icon-values-6 {
    width: (48 / 48) * 1em;
}
.icon-values-7 {
    width: (48 / 48) * 1em;
}
.icon-values-8 {
    width: (48 / 48) * 1em;
}
.icon-values-9 {
    width: (48 / 48) * 1em;
}
.icon-ws {
    width: (19 / 18) * 1em;
}
