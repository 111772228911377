.footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    background: white;
    position: relative;
    z-index: 2;

    @media (max-width: $lg) {
        padding-bottom: 0;
        padding-top: 24px; }

    &__left {
        @media (max-width: $lg) {
            margin-top: 24px; }

        @media (max-width: $md) {
            width: 100%; }

        &-top {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 8px;

            @media (max-width: $lg) {
                margin-bottom: 20px; }

            @media (max-width: $md) {
                margin-bottom: 0;
                gap: 10px;
                justify-content: space-between; }

            >a {
                svg {
                    @media (max-width: $md) {
                        margin-left: -55px; }
                    >g {
                        @media (max-width: $md) {
                            display: none; } } } } }
        &-bottom {
            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 136%;
                color: #5D5E67;

                @media (max-width: $lg) {
                    display: none; } } } }

    &__social {
        display: flex;
        align-items: center;
        gap: 16px;

        a {
            border-radius: 100%;
            width: 32px;
            height: 32px;
            background: $blue;
            display: flex;
            align-items: center;
            justify-content: center; } }

    >.container-main {
        height: 134px;
        @media (max-width: 1680px) {
            max-width: calc( 100vw - 40px ); }

        @media (max-width: $lg) {
            height: 100%;
            flex-direction: column; } }

    .container-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: $lg) {
            align-items: baseline;
            max-width: calc( 100% - 40px );
            flex-direction: column-reverse; }

        .nav {
            @media (max-width: $lg) {
                width: 100%; }

            ul {
                display: flex;
                gap: 32px;
                justify-content: flex-end;
                flex-wrap: wrap;
                max-width: 500px;

                @media (max-width: $lg) {
                    max-width: 100%;
                    justify-content: flex-start; }

                li {
                    @media (max-width: $lg) {
                        width: calc( (100% / 2) - 32px ); } }

                a {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 136%;
                    color: #5D5E67;
                    text-decoration: none;

                    @media (max-width: $lg) {
                        font-size: 15px; } } } } }

    &__bottom {
        height: 42px;
        background: $blue;
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width: $lg) {
            height: auto;
            margin-top: 32px;
            padding: 10px 0; }

        .container-main {
            @media (max-width: $lg) {
                gap: 16px;
                flex-direction: column; } }

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            color: white; }

        .nav {
            ul {
                @media (max-width: $lg) {
                    justify-content: space-between !important;
                    gap: inherit !important; }
                li {
                    @media (max-width: $lg) {
                        width: fit-content !important; }
                    a {
                        color: white !important; } } } } } }
