.ethereum {
    &__row {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $md) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 12px; }

        .table__header {
            display: flex;
            align-items: center;
            gap: 12px;

            @media (max-width: $md) {
                margin-top: 24px;
                width: 100%; }

            .table__btns {
                display: flex;
                align-items: center;
                background: $white;
                height: 46px;
                padding: 4px 8px 4px 8px;
                border-radius: 8px;
                gap: 4px;

                @media (max-width: $md) {
                    width: 100%; }

                &.time {
                    button {
                        padding: 0 12px; } }

                button {
                    padding: 0 24px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 160%;
                    color: $accent;
                    cursor: pointer;
                    transition: all 0.7s ease;
                    height: 100%;
                    border-radius: 8px;

                    @media (max-width: $md) {
                        width: 100%;
                        padding: 0; }

                    &:hover, &.active {
                        background: white; } } } } }

    picture, svg {
        width: 100%;

        @media (max-width: $md) {
            display: none; }

        img {
            border-radius: 16px;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            height: auto;

            @media (max-width: $lg) {
                width: 100%;
                height: auto; } } } }
